import { FC, useState } from 'react';
import classNames from 'classnames';

import CarouselIcon from '../../atoms/CarouselIcon';
import CategoryCard from '../CategoryCard';

import styles from './SubCategoryScroller.module.scss';

const ITEMS_TO_SHOW = 3;

interface Props {
  categories: Array<ScrollItem>;
}

interface ScrollItem {
  imageUrl?: string;
  link: string;
  title: string;
}

// TODO: Determine if it's worth adding a "loader" to prevent loading more categories than necessary. Not sure on the current avg count.
export const SubCategoryScroller: FC<Props> = ({ categories }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const canScrollRight = categories.length > ITEMS_TO_SHOW * (currentPageIndex + 1);

  return (
    <div className={styles.container} data-cy={'sub-category-scroller'}>
      {currentPageIndex > 0 && (
        <div className={styles['arrow-left']}>
          <CarouselIcon
            direction="left"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
          />
        </div>
      )}
      {categories.map((category, index) => (
        <div
          className={classNames(styles['card-container'], {
            [styles.hidden]:
              index < currentPageIndex * ITEMS_TO_SHOW ||
              index >= (currentPageIndex + 1) * ITEMS_TO_SHOW,
          })}
          key={`${category.link}/${category.title}`}
          data-cy={categories.length - 1 === index ? 'last-card' : undefined}
        >
          <CategoryCard
            description={category.title}
            imgAlt={category.title}
            imgUrl={category.imageUrl}
            link={category.link}
          />
        </div>
      ))}
      {canScrollRight && (
        <div className={styles['arrow-right']}>
          <CarouselIcon
            direction="right"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
          />
        </div>
      )}
    </div>
  );
};

export default SubCategoryScroller;
export type { Props as SubCategoryScrollerProps };
