import { FC } from 'react';
import CloseIcon from 'mdi-react/CloseIcon';

import styles from './Tooltip.module.scss';

interface Props {
  handleDismiss: () => void;
  displayCloseIcon?: boolean;
}

const Tooltip: FC<Props> = ({ children, handleDismiss, displayCloseIcon = true }) => (
  <div className={styles.tooltip}>
    <div className={styles['tooltip-label-container']}>
      <div className={styles['tooltip-label']}>{children}</div>
      {displayCloseIcon && (
        <button
          aria-label={'close'}
          className={styles['close-button']}
          onClick={handleDismiss}
          type="button"
        >
          <CloseIcon color="#fff" size="1.5rem" />
        </button>
      )}
    </div>
    <div className={styles['tooltip-arrow']} />
  </div>
);

export default Tooltip;
export type { Props as TooltipProps };
