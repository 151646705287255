import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CategoryCard from '../CategoryCard';
import SubCategoryScroller from '../SubCategoryScroller';

import styles from './CategoryPreviewSubCategoryList.module.scss';

interface Props {
  category: Category;
  isHome?: boolean;
  subCategories: Array<Category>;
  topCategoryId: string;
  verticals: Array<Vertical>;
}

interface Vertical {
  _id: string;
  categories: Array<string>;
  thumbUrl?: string;
  translations: { requestType: string };
}

interface Category {
  caption?: string;
  categoryLevel?: 'c1' | 'c2' | 'c3' | 'vert';
  children?: Record<string, Category>;
  id: string;
  imageUrl?: string;
  pageBanner?: string;
  parentCategories?: Array<string>;
  title: string;
}

export const CategoryPreviewSubCategoryList: FC<Props> = ({
  category,
  isHome = false,
  subCategories,
  verticals,
  topCategoryId,
}) => {
  const { categoryLevel, id, imageUrl, pageBanner, title, parentCategories } = category;
  const { t } = useTranslation('marketingPages');
  let displayableCategories = [];

  if (
    categoryLevel === 'c3' ||
    categoryLevel === 'vert' ||
    (topCategoryId === 'events' && !isHome)
  ) {
    displayableCategories = Object.values(verticals)
      .filter((vert: Vertical) => vert.categories.includes(id))
      .map((vert: Vertical) => ({
        imageUrl: vert.thumbUrl,
        link: `/${vert.categories.join('/')}/${vert._id}`,
        title: vert.translations.requestType,
      }));
  } else {
    displayableCategories = subCategories.map((subCategory) => ({
      imageUrl: subCategory.imageUrl,
      link: subCategory.parentCategories
        ? `/${subCategory.parentCategories.join('/')}/${subCategory.id}`
        : `/${subCategory.id}`,
      title: subCategory.title,
    }));
  }

  const description =
    category.caption ??
    `人気のカテゴリー
  ${displayableCategories[0] ? `、${displayableCategories[0].title}` : ''}
  ${displayableCategories[1] ? `、${displayableCategories[1].title}` : ''}
  ${displayableCategories[2] ? `、${displayableCategories[2].title}` : ''}
  などのプロをご紹介  `;

  return (
    <div className={styles['container']} data-cy={'sub-categories'}>
      {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
      {categoryLevel !== 'vert' && (
        <CategoryCard
          categoryLevel={categoryLevel}
          description={description}
          hasLargeTitle={isHome}
          imgUrl={imageUrl ?? pageBanner ?? ''}
          imgAlt={title}
          isLarge={true}
          link={
            parentCategories && parentCategories.length > 0
              ? `/${parentCategories.join('/')}/${id}`
              : `/${id}`
          }
          title={title}
        />
      )}

      {displayableCategories.length > 0 && (
        <>
          {categoryLevel === 'c1' ? (
            <h4 data-cy={'sub-category-card'}>{t('subCategories', { category: title })}</h4>
          ) : (
            <h5 data-cy={'sub-category-card'}>{t('subCategories', { category: title })}</h5>
          )}
          <div className={styles['list-container']} data-cy={'sub-category-next-page'}>
            <SubCategoryScroller categories={displayableCategories} />
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryPreviewSubCategoryList;
export type { Props as CategoryPreviewSubCategoryListProps };
