import { FC } from 'react';
import Image from 'next/image';
import classNames from 'classnames';

import { Link } from 'routes';
import { useImage } from 'src/hooks';

import styles from './CategoryCard.module.scss';

const WIDTH_TITLE_MOBILE = 100;
const WIDTH_TITLE_DESKTOP = 60;
const WIDTH_DEFAULT_MOBILE = 40;
const WIDTH_DEFAULT_DESKTOP = 25;

interface Props {
  categoryLevel?: string;
  description?: string;
  hasLargeTitle?: boolean;
  imgAlt?: string;
  imgUrl?: string;
  isLarge?: boolean;
  link?: string;
  title?: string;
  onClick?: () => void;
}

export const CategoryCard: FC<Props> = ({
  categoryLevel = '',
  description,
  hasLargeTitle,
  imgAlt,
  imgUrl,
  isLarge,
  link = '',
  title,
  onClick,
}) => {
  const imageProps = useImage(imgUrl);

  return (
    <Link prefetch={false} route={link}>
      <a
        className={classNames(styles.container, { [styles.large]: isLarge })}
        onClick={() => onClick?.()}
        role="link"
        tabIndex={0}
      >
        <div className={styles['image-container']} data-cy={'popular-service-section'}>
          {imgUrl && (
            <Image
              {...imageProps}
              alt={imgAlt}
              className={styles.backdrop}
              layout="fill"
              objectFit="cover"
              sizes={`(max-height: 640px) ${title ? WIDTH_TITLE_MOBILE : WIDTH_DEFAULT_MOBILE}vw, ${
                title ? WIDTH_TITLE_DESKTOP : WIDTH_DEFAULT_DESKTOP
              }vw`}
            />
          )}
          {title && (
            <div className={styles.overlay}>
              {categoryLevel === 'c1' ? (
                <h3
                  className={classNames(styles.title, { [styles['title-large']]: hasLargeTitle })}
                  data-cy={'category-card'}
                >
                  {title}
                </h3>
              ) : (
                <h4
                  className={classNames(styles.title, { [styles['title-large']]: hasLargeTitle })}
                  data-cy={'category-card'}
                >
                  {title}
                </h4>
              )}
            </div>
          )}
        </div>

        {description && <p className={styles.description}>{description}</p>}
      </a>
    </Link>
  );
};

export type { Props as CategoryCardProps };
export default CategoryCard;
