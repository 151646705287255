import { useMemo } from 'react';

import { getBlurDataUrl, getIsUnoptimized, getPlaceholder, imageLoader } from 'utils/image-loader';

const useImage = (src = '') => {
  const result = useMemo(
    () =>
      src
        ? {
            blurDataURL: getBlurDataUrl(src),
            loader: imageLoader,
            placeholder: getPlaceholder(src),
            src,
            unoptimized: getIsUnoptimized(src),
          }
        : { src },
    [src]
  );

  return result;
};

export default useImage;
