import { makeUseAxios } from 'axios-hooks';

import httpClient from 'src/http-client';

const useApi = makeUseAxios({
  axios: httpClient,
  cache: process.env.NODE_ENV === 'test' ? false : undefined,
});

export default useApi;
