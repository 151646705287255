import Image from 'next/image';
import { FC } from 'react';

import styles from './CarouselIcon.module.scss';

interface Props {
  direction?: 'left' | 'right';
  onClick: () => void;
}

const CarouselIcon: FC<Props> = ({ direction = 'right', onClick }) => (
  <button className={styles['round-background']} onClick={onClick}>
    <Image
      src={`/static/images/icons/Icon_${direction === 'left' ? 'Left' : 'Right'}_Grey.svg`}
      alt={direction === 'left' ? 'Previous' : 'Next'}
      width="25px"
      height="25px"
    />
  </button>
);

export default CarouselIcon;
export type { Props as CarouselIconProps };
