import { ImageProps } from 'next/image';

import { addTransformToCloudinaryUrl, isCloudinaryUrl } from './url';

enum Type {
  Cloudinary,
  Other,
}

const getType = (src: string) => {
  if (isCloudinaryUrl(src)) return Type.Cloudinary;
  return Type.Other;
};

// Issue about unnecessary warnings
// https://github.com/vercel/next.js/issues/28215
export const imageLoader: ImageProps['loader'] = ({ src, quality, width }) => {
  switch (getType(src)) {
    case Type.Cloudinary:
      return addTransformToCloudinaryUrl(src, { crop: 'fill', dpr: '1.0', quality, width });

    default:
      return src;
  }
};

export const getBlurDataUrl = (src: string, width?: number): ImageProps['blurDataURL'] => {
  switch (getType(src)) {
    case Type.Cloudinary:
      return addTransformToCloudinaryUrl(src, { crop: 'fill', dpr: '1.0', quality: 10, width });

    default:
      return undefined;
  }
};

export const getIsUnoptimized = (src: string): ImageProps['unoptimized'] => {
  switch (getType(src)) {
    case Type.Cloudinary:
      return false;

    default:
      return true;
  }
};

export const getPlaceholder = (src: string): ImageProps['placeholder'] => {
  switch (getType(src)) {
    case Type.Cloudinary:
      return 'blur';

    default:
      return 'empty';
  }
};
