import { useMemo } from 'react';

import type { DraftRequest } from 'modules/requests/components/NewRequestForm/NewRequestForm';
import SocialAnalytics from 'utils/analytics/social';
import { JOB_TYPE_ID_DEFAULT } from 'utils/job-type';
import { useLocalStorage } from 'src/hooks';
import { ContextEnum, LOCAL_STORAGE_KEYS } from '@z-components/utils/oauth';
import type { SocialRequestMeta } from '@z-components/utils/oauth';

const useSocialRequestContinuation = (jobTypeId?: string) => {
  const [socialRequestMeta] = useLocalStorage<SocialRequestMeta>(
    LOCAL_STORAGE_KEYS.SOCIAL_REQUEST_META,
    {
      jobTypeId: JOB_TYPE_ID_DEFAULT,
      socialRequestId: null,
    }
  );
  const [draftRequests] = useLocalStorage<Record<string, DraftRequest | undefined>>(
    'draftRequests',
    {}
  );

  const hasVisitedSocialField = useMemo(() => {
    const socialRequestOriginJobTypeId =
      draftRequests[socialRequestMeta.jobTypeId]?.originJobTypeId ?? 'unset';

    return (
      socialRequestMeta.hasVisitedSocialField &&
      (jobTypeId
        ? socialRequestMeta.jobTypeId === jobTypeId || socialRequestOriginJobTypeId === jobTypeId
        : socialRequestMeta.jobTypeId in draftRequests) &&
      !socialRequestMeta.socialRequestId
    );
  }, [
    jobTypeId,
    socialRequestMeta.hasVisitedSocialField,
    socialRequestMeta.jobTypeId,
    socialRequestMeta.socialRequestId,
    draftRequests,
  ]);

  if (hasVisitedSocialField) {
    SocialAnalytics.getInstance({
      context: ContextEnum.requestForm,
      jobTypeId: socialRequestMeta.jobTypeId,
      provider: 'line',
    }).socialUserBackedFromFlow();
  }

  return { hasVisitedSocialField, vertical: socialRequestMeta.jobTypeId };
};

export default useSocialRequestContinuation;
