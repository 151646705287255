import { FC, FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';

import Autosuggest, { AutosuggestProps } from 'components/atoms/Autosuggest';
import { useApi, useDebounce } from 'src/hooks';
import { logEvent } from 'utils/analytics';
import Button from '@z-components/atoms/Button';
import Tooltip from '@z-components/atoms/Tooltip';

import {
  trackingEventForRequestCreation,
  GA4EventRequestCreation,
} from 'utils/eventTrackingForGA4';

import { ReduxProps } from './JobTypeSearch.container';
import { JobType } from 'modules/job-types/types';

import styles from './JobTypeSearch.module.scss';
import { createTelemetryBeacon, sendTelemetryBeacon } from 'utils/telemetry';

const DEBOUNCE_TIME = 250;
const POPULAR_JOBS_COUNT = 5;

interface Props {
  buttonText?: string;
  id: string;
  isButtonAnimated?: boolean;
  pageType?: string;
  popular?: Array<Pick<JobType, 'id' | 'title'>>;
}

const JobTypeSearch: FC<Props & ReduxProps> = ({
  buttonText,
  id,
  isButtonAnimated = false,
  openRequestModal,
  pageType = 'top',
  popular,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const queryValue = useDebounce(inputValue.trim(), DEBOUNCE_TIME);
  const [{ data: jobTypes, loading }] = useApi<
    Array<{ categories: Array<string> | undefined; id: string; title: string }>
  >(
    {
      params: { query: queryValue, v: 1 },
      url: '/job-types/query',
    },
    { manual: !queryValue }
  );

  const handleChange = useCallback<AutosuggestProps['onInputChange']>((value) => {
    setInputValue(value);
    setShowTooltip(false);
  }, []);

  useEffect(() => {
    if (queryValue) {
      sendTelemetryBeacon(
        createTelemetryBeacon({
          action: 'jobTypeSearch_query',
          context: `${pageType}_jobTypeSearch`,
          content: queryValue,
        })
      );
    }
  }, [queryValue, pageType]);

  const handleSubmit = useCallback<FormEventHandler>(
    (event) => {
      event.preventDefault();
      const jobType = jobTypes?.find(({ title }) => title === inputValue);
      const jobTypeId = jobType?.id;
      if (!jobTypeId) {
        logEvent('JobTypeSearch', 'startNewRequestFailFuzzySearch', inputValue);
        setShowTooltip(true);
        return;
      }
      logEvent('JobTypeSearch', 'startNewRequestSuccessFuzzySearch', jobTypeId);

      sendTelemetryBeacon(
        createTelemetryBeacon({
          action: 'jobTypeSearch_select',
          context: `${pageType}_jobTypeSearch`,
          content: jobTypeId,
        })
      );

      trackingEventForRequestCreation(GA4EventRequestCreation.startNewRequestClicked, {
        // eslint-disable-next-line id-length
        C1: jobType?.categories?.[0],
        // eslint-disable-next-line id-length
        C2: jobType?.categories?.[1],
        jobTypeId,
        whereFromForOpenRequestForm: pageType === 'top' || !pageType ? pageType : `${pageType}Lp`,
      });
      openRequestModal({ jobTypeId });
    },
    [inputValue, jobTypes, openRequestModal, pageType]
  );

  const handleDismiss = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const defaultJobTypes = useMemo(() => _.sampleSize(popular, POPULAR_JOBS_COUNT), [popular]);

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles['input-tooltip-container']}>
        {showTooltip && (
          <Tooltip handleDismiss={handleDismiss}>
            {t('jobTypeSelect.tooltipLabel')}
            <br />
            {t('jobTypeSelect.tooltipSubLabel')}
          </Tooltip>
        )}
        <div className={styles['input-container']}>
          <Autosuggest
            ariaLabel={t('jobTypeSelect.label')}
            id={id}
            isLoading={loading}
            onInputChange={handleChange}
            options={
              (jobTypes ?? defaultJobTypes)
                .map((jobType) => ({ label: jobType.title, value: jobType.id }))
                .filter(Boolean) as AutosuggestProps['options']
            }
            placeholder={t('jobTypeSelect.placeholder')}
          />
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          className={classNames('z-btn-primary-job-search', { 'z-btn-animated': isButtonAnimated })}
          testId="start-new-request"
          type="submit"
        >
          {buttonText ?? t('jobTypeSelect.getStarted')}
        </Button>
      </div>
    </form>
  );
};

export default JobTypeSearch;
