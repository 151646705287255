import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { setNewRequestModalProperties } from 'modules/requests/duck';
import { openNewRequestModal } from 'modules/requests/service';
import { RootState } from 'store/types';

import { listClientRequestableSelector } from '../../duck';
import JobTypeSearch from './JobTypeSearch';

const mapStateToProps = (state: RootState) => ({
  jobTypes: listClientRequestableSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  openRequestModal(properties: Parameters<typeof setNewRequestModalProperties>[0]) {
    return openNewRequestModal({
      dispatch,
      properties,
    });
  },
});

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(JobTypeSearch);
