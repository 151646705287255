import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import SpinnerIcon from 'images/spinner.svg';

import styles from './Autosuggest.module.scss';

const PAGE_KEYS_STEP = 5;

interface Option {
  label: string;
  value: string;
}
interface Props {
  ariaLabel?: string;
  id: string;
  isLoading?: boolean;
  onInputChange: (value: string) => void;
  onSelect?: (option: Option) => void;
  options?: Array<Option>;
  placeholder?: string;
}

const Autosuggest: FC<Props> = ({
  ariaLabel,
  id,
  isLoading = false,
  onInputChange,
  onSelect = () => undefined,
  options = [],
  placeholder,
}) => {
  const listId = `${id}-list`;
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const mouseDownTarget = useRef<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [isListboxShown, showListbox] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const handleInputFocus = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    showListbox(true);
  }, []);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    showListbox(true);
    setInputValue(event.target.value);
    setHighlightedIndex(0);
  }, []);
  // Call onInputChange if value is changed
  useEffect(() => {
    onInputChange(inputValue);
  }, [inputValue, onInputChange]);

  const handleItemNavigation = useCallback(
    (delta: number, continuous = false) => {
      setHighlightedIndex((currentIndex) => {
        const adjustedIndex = currentIndex + delta;
        if (continuous) {
          const updatedIndex = adjustedIndex % options.length;
          return adjustedIndex < 0 ? options.length + updatedIndex : updatedIndex;
        }
        return _.clamp(adjustedIndex, 0, options.length - 1);
      });
    },
    [options]
  );
  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.nativeEvent.isComposing) {
        return;
      }
      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowUp':
        case 'PageDown':
        case 'PageUp': {
          event.preventDefault();
          event.stopPropagation();
          if (options.length === 0) {
            return;
          }
          showListbox(true);
          const delta = event.key.startsWith('Arrow') ? 1 : PAGE_KEYS_STEP;
          handleItemNavigation(
            event.key.endsWith('Up') ? -delta : delta,
            event.key.startsWith('Arrow')
          );
          break;
        }

        case 'Enter':
        case 'Tab':
          if (isListboxShown && options.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            showListbox(false);
            setHighlightedIndex(0);
            setInputValue(options[highlightedIndex].label);
            onInputChange(options[highlightedIndex].label);
            onSelect(options[highlightedIndex]);
          }
          break;

        case 'Escape':
          if (isListboxShown) {
            event.preventDefault();
            event.stopPropagation();
            showListbox(false);
          }
          break;

        default:
          break;
      }
    },
    [handleItemNavigation, highlightedIndex, isListboxShown, onInputChange, onSelect, options]
  );

  const handleWindowMouseDown = useCallback<EventListener>((event) => {
    const target = event.target as HTMLElement;
    mouseDownTarget.current = target;
  }, []);
  const handleWindowMouseUp = useCallback<EventListener>((event) => {
    const target = event.target as HTMLElement;
    if (mouseDownTarget.current === target && !containerRef.current?.contains(target)) {
      showListbox(false);
    }
  }, []);
  // Add/remove outside click listeners
  useEffect(() => {
    if (isListboxShown) {
      window.addEventListener('mousedown', handleWindowMouseDown);
      window.addEventListener('mouseup', handleWindowMouseUp);
    }

    return () => {
      if (isListboxShown) {
        window.removeEventListener('mousedown', handleWindowMouseDown);
        window.removeEventListener('mouseup', handleWindowMouseUp);
      }
    };
  }, [handleWindowMouseDown, handleWindowMouseUp, isListboxShown]);

  // Keep highlighted element visible by scrolling
  useEffect(() => {
    if (!isListboxShown || !listRef.current) {
      return;
    }
    const highlightedElement = listRef.current.children.item(
      highlightedIndex
    ) as HTMLElement | null;
    if (!highlightedElement) {
      return;
    }
    const {
      offsetHeight: highlightedOffsetHeight,
      offsetTop: highlightedOffsetTop,
    } = highlightedElement;
    const { offsetHeight: containerOffsetHeight, scrollTop } = listRef.current;
    if (highlightedOffsetTop < scrollTop) {
      // Scrolled upwards
      listRef.current.scrollTo(0, highlightedOffsetTop);
    } else if (highlightedOffsetTop + highlightedOffsetHeight > scrollTop + containerOffsetHeight) {
      // Scroll downwards
      listRef.current.scrollTo(
        0,
        highlightedOffsetTop + highlightedOffsetHeight - containerOffsetHeight
      );
    }
  }, [highlightedIndex, isListboxShown]);

  return (
    <div className={styles.container} ref={containerRef}>
      <input
        aria-activedescendant={highlightedIndex ? `${listId}-${highlightedIndex}` : ''}
        aria-autocomplete="list"
        aria-controls={listId}
        aria-expanded={isListboxShown}
        aria-haspopup="listbox"
        aria-label={ariaLabel}
        aria-owns={listId}
        className={classNames(styles.input, { [styles['input-loading']]: isLoading })}
        id={id}
        onChange={handleChange}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        role="combobox"
        type="text"
        autoComplete="off"
        value={inputValue}
      />
      {isListboxShown && options.length > 0 && (
        <ul className={styles.list} id={listId} role="listbox" ref={listRef}>
          {options.map((option, index) => (
            <li
              aria-selected={index === highlightedIndex}
              id={`${listId}-${index}`}
              key={option.value}
              role="option"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                setInputValue(option.label);
                onSelect(option);
                showListbox(false);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      {isLoading && (
        <div className={styles.spinner}>
          <SpinnerIcon className="svg-spin" color="#545454" />
        </div>
      )}
    </div>
  );
};

export type { Props as AutosuggestProps, Option };
export default Autosuggest;
